import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { LuImagePlus } from "react-icons/lu";
import { MdFileDownloadDone } from "react-icons/md";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
export const Upload = () => {
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const [isUploading, setUploading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  //post form constans
  const [images, setImages] = useState({});
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState("خانوو");
  const [rentOrSell, setRentOrSell] = useState("فرۆشتن");
  const [price, setPrice] = useState("");
  const [priceType, setpriceType] = useState("دەفتەر");
  const [futures, setFutures] = useState([]);
  const [location, setLocation] = useState();
  const [permission, setPermission] = useState(false);
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  const [center, setCenter] = useState({
    lat: 36.18821396643311,
    lng: 44.010066159349506,
  });

  const handleAddFuture = (value) => {
    setFutures((prevFutures) => [...prevFutures, value]);
  };

  const handleRemoveFuture = (index) => {
    setFutures((prevFutures) => prevFutures.filter((_, idx) => idx !== index));
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleRentOrSellChange = (e) => {
    setRentOrSell(e.target.value);
  };
  const handlePriceType = (e) => {
    setpriceType(e.target.value);
  };
  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "https://api.imgbb.com/1/upload?key=a494e9a0212cebf741b10bcb8f803a3f",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image.");
      }

      const data = await response.json();
      // console.log(data);
      setImages((prevImages) => ({
        ...prevImages,
        [index]: data.data.url,
      }));
    } catch (error) {
      console.error(error);
    }
  };
  // Function to reorder the image array to start from index 0
  const reorderImages = () => {
    let reorderedImages = {};
    Object.keys(images).forEach((key, index) => {
      reorderedImages[index] = images[key];
    });
    setImages(reorderedImages);
  };
  const handlePost = async () => {
    const errors = {};

    setUploading(true);
    if (!title) {
      errors.title = true;
    }
    if (!about) {
      errors.about = true;
    }
    if (!price) {
      errors.price = true;
    }
    if (!address) {
      errors.address = true;
    }
    if (!location) {
      errors.location = true;
    }
    if (
      Object.keys(images).filter((key) => images[key] !== null).length === 0
    ) {
      errors.image = true;
    }
    if (Object.keys(errors).length > 0) {
      setUploading(false);

      setErrors(errors);
      // console.log(errors);
      return;
    }
    // Reorder the images array before submitting the post
    reorderImages();
    try {
      // Construct the data object to send in the request body
      const postData = {
        postUserId: userId,
        title: title,
        price: price,
        images: images,
        type: type,
        sellOrRent: rentOrSell,
        about: about,
        address: address,
        futures: futures,
        position: location,
        phoneNumber: phoneNumber,
        // Add other form fields to the postData object
      };

      // Make a POST request to your server
      const response = await fetch(
        "https://nwsinga-server.onrender.com/post",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create post.");
      }

      const responseData = await response.json();
      // console.log("Post created successfully:", responseData);
      setUploading(false);
      setUploaded(true);
      // Optionally, handle success response
    } catch (error) {
      console.error("Error creating post:", error);
      // Optionally, handle error
    }
  };
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPermission(true);
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  function DraggableMarker() {
    const [draggable, setDraggable] = useState(true);
    const [position, setPosition] = useState(center);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            // console.log(marker.getLatLng());
            setLocation(marker.getLatLng());
            setCenter(marker.getLatLng());
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>ناونیشانی موڵکەکەت دیاری بکە</span>
        </Popup>
      </Marker>
    );
  }

  const handleGoBack = () => {
    window.history.back();
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://nwsinga-server.onrender.com/user/${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user data.");
        }
        const userData = await response.json();
        setPhoneNumber(userData.phoneNumber);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);
  return (
    <div className="upload w-full h-auto pl-2 pr-2 pb-32 flex flex-col  items-center gap-4 ">
      {isUploading ? (
        <div className="uploading absolute w-full h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-64 h-64 rounded-lg center font-kurdish_043  ">
            <ReactLoading
              type="spin"
              height={"40%"}
              width={"40%"}
              color="#5b47d6"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {isUploaded ? (
        <div className="uploading absolute w-full h-[300vh] backdrop-blur-lg z-[99999] flex justify-center pt-80">
          <div className="box w-80 h-80 rounded-lg border-2 border-primary-100 center font-kurdish_043 text-2xl text-center text-primary-100 font-bold bg-white flex-col gap-8 ">
            بە سەرکەوتووی دانرا
            <MdFileDownloadDone className="text-8xl" />
            <Link
              to={"/"}
              className="tohome text-xl px-4 py-2 bg-primary-100 text-white rounded-md"
            >
              گەڕانەوە بۆ بەشی سەرەکی
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Top Bar */}
      <div className="topBar w-full h-24 p-4 flex justify-between items-center gap-4">
        {/* Back Button */}
        <div
          onClick={handleGoBack}
          className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer"
        >
          <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-8xl">
            <IoIosArrowRoundBack />
          </div>
        </div>
        <div className="price flex-[5] p-2 h-full">
          <div className="priceBack w-full h-full rounded-full bg-white center font-kurdish_043 text-primary-100 text-4xl">
            دانانی موڵک
          </div>
        </div>
        {/* Favorite Button */}
        <div className="Favorite flex-[1] p-2 h-full hover:cursor-pointer"></div>
      </div>
      <div className="title w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="titleLabel text-primary-100 ">دانانی وێنە</div>
        {errors.image ? (
          <div className="titleLabel text-xs text-red-600 ">
            تکایە بەلایەنی کەمەوە یەک وێنە هەڵبژێرە
          </div>
        ) : (
          <></>
        )}
        <div className="imageContainer  w-full p-4 h-64 grid grid-cols-3 gap-4 items-center ">
          {/* Preview of the selected image */}
          {Array.from({ length: 6 }).map((_, index) => (
            <div
              key={index}
              className="imageViewr relative w-full h-full flex justify-start items-end flex-col flex-1"
            >
              {images[index] && (
                <img
                  src={images[index]}
                  alt={`Preview ${index}`}
                  className="w-full h-full  object-cover rounded-lg shadow-lg border-2 border-primary-100"
                />
              )}
              <input
                type="file"
                id={`imageUpload${index}`}
                accept="image/*"
                className="hidden"
                onChange={(e) => handleImageUpload(e, index)}
              />
              {!images[index] && (
                <label
                  htmlFor={`imageUpload${index}`}
                  className="cursor-pointer h-full w-full flex-1 center bg-primary-500 rounded-lg p-2 border-2 border-primary-100"
                >
                  <LuImagePlus className="text-4xl text-primary-100" />
                </label>
              )}
              {images[index] && (
                <MdDelete
                  className="absolute z-10 text-red-600 text-4xl top-2 right-2 cursor-pointer"
                  onClick={() =>
                    setImages((prevImages) => ({
                      ...prevImages,
                      [index]: null,
                    }))
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="title w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="titleLabel text-primary-100 ">سەردێڕ</div>
        <input
          type="text"
          className={`w-full h-full p-2 text-center rounded-md ${
            errors.title ? "br" : "border-2 border-primary-100"
          }`}
          placeholder="سەردێری موڵکەکەت بنوسە"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="address w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="addressLable text-primary-100 ">ناونیشان</div>
        <input
          type="text"
          className={`w-full h-full p-2 text-center rounded-md ${
            errors.address ? "br" : "border-2 border-primary-100"
          }`}
          placeholder="ناونیشانی موڵکەکەت بنوسە"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="about w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="aboutLabel text-primary-100 ">دەربارە</div>
        <input
          type="text"
          className={` w-full h-32 p-2 text-wrap text-center ${
            errors.about ? "br" : "border-2 border-primary-100"
          } rounded-md `}
          placeholder="دەربارەی موڵکەکەت بنوسە"
          onChange={(e) => {
            setAbout(e.target.value);
          }}
        />
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="type w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="type text-primary-100 ">جۆری موڵک</div>
        <select
          className=" w-full h-auto p-2 text-wrap text-center border-2 border-primary-100 rounded-md "
          value={type}
          onChange={handleTypeChange}
        >
          <option value="خانوو">خانوو</option>
          <option value="زەوی">زەوی</option>
          <option value="ڤێلا">ڤێلا</option>
          <option value="شوقە">شوقە</option>
          <option value="دوکان">دوکان</option>
        </select>
        <select
          className="rentOrs=Sell w-full h-auto p-2 text-wrap text-center border-2 border-primary-100 rounded-md "
          value={rentOrSell}
          onChange={handleRentOrSellChange}
        >
          <option value="فرۆشتن">فرۆشتن</option>
          <option value="کرێ">کرێ</option>
        </select>
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="about w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="aboutLabel text-primary-100 ">نرخ</div>
        <input
          type="text"
          className={`w-full h-full p-2 text-center rounded-md ${
            errors.price ? "br" : "border-2 border-primary-100"
          }`}
          placeholder="نرخی موڵکەکەت بنوسە"
          onChange={(e) => {
            setPrice(e.target.value);
          }}
        />
        <select
          className=" w-full h-auto p-2 text-wrap text-center border-2 border-primary-100 rounded-md "
          value={priceType}
          onChange={handlePriceType}
        >
          <option value="دەفتەر">دەفتەر</option>
          <option value="دۆلار">دۆلار</option>
          <option value="گەڵا">گەڵا</option>
        </select>
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="future w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="aboutLabel text-primary-100 ">تایبەتمەندیەکان</div>
        <div className="futureAdd flex gap-2 w-full ">
          <button
            className="flex-[1] bg-primary-100 text-white rounded-lg"
            onClick={() => {
              const inputValue = document.getElementById("futureInput").value;
              if (inputValue.trim() !== "") {
                handleAddFuture(inputValue);
                document.getElementById("futureInput").value = "";
              }
            }}
          >
            زیادکردن
          </button>
          <input
            id="futureInput"
            type="text"
            className="flex-[2] h-auto p-2 text-wrap text-center border-2 border-primary-100 rounded-md"
            placeholder="  ژمارەی ژوور ، ڕووبەڕ "
          />
        </div>
        <div className="future w-full h-auto  gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
          {futures.map((future, index) => (
            <div key={index} className="futureAdd flex gap-2 w-full ">
              <button
                className="flex-[1] bg-red-600 text-white rounded-lg"
                onClick={() => handleRemoveFuture(index)}
              >
                سڕینەوە
              </button>
              <span className="flex-[2] h-auto p-2 text-wrap text-center border-2 border-primary-100 rounded-md">
                {future}
              </span>
            </div>
          ))}
        </div>
      </div>
      <hr className="w-full border-2 border-opacity-25 border-primary-100" />
      <div className="map w-full h-auto p-2 gap-2  flex flex-col  justify-center items-center text-xl font-kurdish_043">
        <div className="mapLabel text-primary-100 ">ماپ</div>

        {errors.image ? (
          <div className="titleLabel text-xs text-red-600 ">
            تکایە لۆکەیشنی موڵکەکەت لەسەر ماپ دیاری بکە
          </div>
        ) : (
          <div className="titleLabel text-xs ">
            تکایە لۆکەیشنی موڵکەکەت لەسەر ماپ دیاری بکە
          </div>
        )}
        <div
          className={`map w-full   aspect-square p-4 ${
            errors.location ? "br" : ""
          }`}
        >
          <MapContainer
            center={center}
            zoom={8}
            scrollWheelZoom={true}
            className="w-full h-full"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <DraggableMarker />
          </MapContainer>
        </div>
      </div>
      <div className="postUpload fixed z-[9999] bottom-0 w-full h-24 bg-primary-100 rounded-t-xl center">
        <button
          className=" text-white border-4 rounded-md border-white p-2 text-xl font-kurdish_043"
          onClick={handlePost}
        >
          بڵاوکردنەوە
        </button>
      </div>
    </div>
  );
};
