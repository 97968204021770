import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import icon from "../../assets/images/icon.png";

export const UserProfile = () => {
  const { userId } = useParams();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId1 = userId;
  const [posts, setPosts] = useState([]);
  const [fill, setFill] = useState([]);

  const fetchUserData = async () => {
    try {
      const userId = userId1; // Replace with the actual user ID

      const response = await fetch(`https://nwsinga-server.onrender.com/user/${userId}`);

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const data = await response.json();
      setUserData(data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  };
  const fetchPosts = async () => {
    try {
      const userId = userId1; // Replace with the actual user ID
      const response = await fetch(`https://nwsinga-server.onrender.com/posts/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }
      const data = await response.json();
      setPosts(data.data);
      // console.log(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserData();
    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className=" w-full h-full fixed z-[9999999] backdrop-blur-lg flex justify-center items-center">
        <img src={icon} alt="" className=" w-32" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // const handleheart = (index) => {
  //   const updateFill = [...fill];
  //   updateFill[index] = !updateFill[index];
  //   setFill(updateFill);
  // };

  const handleGoBack = () => {
    // Go back one step
    window.history.back();
  };
  return (
    <section>
      <div className="h-[270px] flex-col space-y-4 bg-primary-100">
        <div className="flex justify-between items-center ">
          <div
            onClick={handleGoBack}
            className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer center text-white text-3xl pt-4"
          >
            <IoIosArrowRoundBack />
          </div>
          <h1 className="flex-1 pt-4 text-md text-white font-kurdish_043 text-center">
            {userData && userData.name}
          </h1>
          <h1 className="flex-1 pt-4 text-3xl text-white center "></h1>
        </div>
        {/* پڕۆفایل */}
        <div className="flex justify-center">
          <div className="avatar flex-col justify-center items-center ">
            <div className="w-24 rounded-full ">
              {userData && (
                <img
                  src={userData.profileUrl || "https://via.placeholder.com/150"}
                  alt=""
                />
              )}
            </div>
            <p className="pt-2 font-thin text-sm text-white font-kurdish_041">
              {userData && userData.username}
            </p>
            <p className="pt-2 font-thin text-xs text-white font-kurdish_041">
              {userData && userData.bio}
            </p>
          </div>
        </div>
       
      </div>
      <div className="posts w-full grid grid-cols-2 md:grid-cols-4 gap-4 h-full pb-32 px-2 py-4">
        {posts.map((post, index) => (
          <Link
            to={`/post/${post.postId}`}
            className="carousel-item"
            key={index}
          >
            <div className="card min-w-full relative shadow-lg mb-6 pb-2">
              <div className="image relative">
               
                <p className="text-xs flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute top-0 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg ">
                  {post.address}
                  <CiLocationOn className="text-xs" />
                </p>
                <img
                  src={post.images[0]} 
                  className="w-full  aspect-video rounded-tr-lg rounded-tl-lg"
                  alt={post.title}
                />
              </div>
              <div className="title center p-2">
                <p className="center font-kurdish_043 text-sm">{post.title}</p>
              </div>
              <div className="flex p-2 gap-1">
                <div className="flex-[1] flex flex-col text-center bg-primary-100 text-white rounded-md">
                  <h6 className="font-semibold font-kurdish_042 px-4 py-2 center gap-2 text-sm">
                    <span>{post.priceType}</span>
                    <span>{post.price}</span>
                  </h6>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
