import React, { useEffect, useState } from "react";
import Banner from "../Elements/Banner";

import Posts from "../Posts/Posts";
import { Category } from "../Category/Category";
import icon from "../../assets/images/icon.png";

export const Home = () => {
  const [filter, setFilter] = useState("none");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="bg-white">
      {isLoading ? (
        <div className=" w-full h-full fixed z-[9999999] backdrop-blur-lg flex justify-center items-center">
          <img src={icon} alt="" className=" w-32" />
        </div>
      ) : (
        <></>
      )}

      <Banner />
      <Category setFilter={setFilter} />
      {/* <Companies /> */}
      <Posts filter={filter} />
    </div>
  );
};
