import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { IoSettingsOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { SideBarSettings } from "../SideBarSettings/SideBarSettings";
import icon from "../../assets/images/icon.png";

export const Profile = (props) => {
  const cookies = new Cookies();
  const [isOpen, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId1 = cookies.get("userId");
  const [posts, setPosts] = useState([]);
  const [fill, setFill] = useState([]);

  const fetchUserData = async () => {
    try {
      const userId = userId1; // Replace with the actual user ID

      const response = await fetch(
        `https://nwsinga-server.onrender.com/user/${userId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const data = await response.json();
      setUserData(data.data);
      // console.log(data.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  };
  const fetchPosts = async () => {
    try {
      const userId = userId1; // Replace with the actual user ID
      const response = await fetch(
        `https://nwsinga-server.onrender.com/posts/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }
      const data = await response.json();
      setPosts(data.data);
      // console.log(data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUserData();
    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className=" w-full h-full fixed z-[9999999] backdrop-blur-lg flex justify-center items-center">
        <img src={icon} alt="" className=" w-32" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleheart = (index) => {
    const updateFill = [...fill];
    updateFill[index] = !updateFill[index];
    setFill(updateFill);
  };
  return (
    <section>
      <div className="flex p-4 flex-col space-y-4 bg-primary-100">
        {isOpen ? (
          <SideBarSettings logOut={props.handleLogOut} setOpen={setOpen} />
        ) : (
          <></>
        )}

        <div className="flex justify-around items-center">
          <h1 className="flex-1"></h1>
          <h1 className="flex-1 pt-4 text-3xl text-white font-kurdish_043 text-center">
            {userData && userData.name}
          </h1>
          <h1 className="flex-1 pt-4 text-3xl text-white center ">
            <IoSettingsOutline
              onClick={() => {
                setOpen(!isOpen);
              }}
            />
          </h1>
        </div>
        {/* پڕۆفایل */}
        <div className="flex justify-center">
          <div className="avatar flex-col justify-center items-center ">
            <div className="w-24 rounded-full ">
              {userData && (
                <img
                  src={userData.profileUrl || "https://via.placeholder.com/150"}
                  alt=""
                />
              )}
            </div>
            <p className="pt-2 font-thin text-sm text-white font-kurdish_041">
              {userData && userData.username}
            </p>
            <p className="pt-2 font-thin text-xs text-white font-kurdish_041">
              {userData && userData.bio}
            </p>
          </div>
        </div>
        {/* <div className="flex gap-4 justify-center">
          <IoChatboxEllipsesOutline
            className="border-non rounded-full p-[6px] w-12 h-11 bg-[#4d3db0]"
            size={40}
            color="white"
          />
          <IoHeartOutline
            className="border-non rounded-full p-1 w-12 h-11 bg-[#4d3db0] "
            size={40}
            color="white"
          />
          <Link to={`tel:0964${userData.phoneNumber}`}>
            <FiPhoneCall
              className="border-non rounded-full p-2 w-[50px] h-11 w- bg-[#4d3db0]"
              size={40}
              color="white"
            />
          </Link>
        </div> */}
      </div>
      {/* بەشی بڵاوکراواکان */}
      {/* <Blawkrawakan/> */}
      <div className="posts w-full grid grid-cols-2  md:grid-cols-4 gap-4 h-full pb-32 px-2 py-4">
        {posts.map((post, index) => (
          <Link
            to={`/post/${post.postId}`}
            className="carousel-item"
            key={index}
          >
            <div className="card min-w-full relative shadow-lg mb-6 pb-2">
              <div className="image relative">
                {/* <img
                src={Crown}
                className="absolute left-3 h-8 w-8 bg-[#e1b600] rounded-b-lg"
                alt="h"
              /> */}
                {/* <MdEdit className="absolute left-0 text-primary-100 h-8 w-8" /> */}
                <p className="text-xs flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute top-0 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg ">
                  {post.address}
                  <CiLocationOn className="text-xs" />
                </p>
                <img
                  src={post.images[0]} // Assuming the first image is the main image
                  className="w-full  aspect-video rounded-tr-lg rounded-tl-lg"
                  alt={post.title}
                />
              </div>
              <div className="title center p-2">
                <p className="center font-kurdish_043 text-sm">{post.title}</p>
              </div>
              <div className="flex p-2 gap-1">
                <div className="flex-[1] flex flex-col text-center bg-primary-100 text-white rounded-md">
                  <h6 className="font-semibold font-kurdish_042 px-4 py-2 center gap-2 text-sm">
                    <span>{post.priceType}</span>
                    <span>{post.price}</span>
                  </h6>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
