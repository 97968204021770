import React, { useEffect, useState } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useIsAdmin } from "../../Hooks/useIsAdmin"; // Import the useIsAdmin hook correctly
import Cookies from "universal-cookie";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import icon from "../../assets/images/icon.png";

export const Notification = () => {
  const cookies = new Cookies(null, { path: "/" });

  const [isAdmin, checkIsAdmin] = useIsAdmin(); // Use the useIsAdmin hook
  const [message, setMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const fetchNotifications = async () => {
    try {
      const response = await fetch("https://nwsinga-server.onrender.com/notification");
      if (!response.ok) {
        throw new Error("Failed to fetch notifications");
      }
      const data = await response.json();
      setNotifications(data.data);
    } catch (error) {
      console.error("Failed to fetch notifications: ", error);
    }
  };

  useEffect(() => {
    const userId = cookies.get("userId");
    if (userId) {
      checkIsAdmin(userId);
      fetchNotifications();
    }
  }, [cookies.get("userId")]); // Trigger useEffect when userId changes

  const handleAddNotification = async () => {
    if (message == "") {
      return;
    }
    try {
      const response = await fetch("https://nwsinga-server.onrender.com/notification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error("Failed to add notification");
      }
      setMessage("");
      // Fetch notifications again after adding a new one
      fetchNotifications();
    } catch (error) {
      console.error("Failed to add notification: ", error);
    }
  };

  return (
    <div className="flex justify-start flex-col items-center" >
      {isLoading ? (
        <div className=" w-full h-full fixed z-[9999999] backdrop-blur-lg flex justify-center items-center">
          <img src={icon} alt="" className=" w-32" />
        </div>
      ) : (
        <></>
      )}
      <div className="h-auto w-full bg-primary-200 relative z-20 p-4">
        <div className="flex justify-center items-center pt-2 ">
          <div className="flex items-center text-center p-2">
            <h2 className="font-bold text-white font-kurdish_043">
              ئاگادارکردنەوەکان
            </h2>
          </div>
        </div>
      </div>
      <div className=" p-2 w-full md:max-w-[32rem] flex flex-col justify-start items-center pt-4 gap-4 pb-32  h-full">
        {isAdmin && isAdmin ? (
          <>
            <div className="notify p-2 gap-2 border-2 shadow-lg border-primary-100 rounded-md w-full flex   ">
              <div className=" aspect-square flex-[1]">
                <div
                  onClick={handleAddNotification}
                  className="send notification w-full h-full center text-white text-3xl aspect-square  rounded-full bg-primary-100"
                >
                  <HiOutlinePlusSmall />
                </div>
              </div>

              <div className=" border-2 border-primary-100 rounded-md  flex-[7] center text-primary-100 font-kurdish_043">
                <input
                  type="text"
                  className=" w-full h-full  p-2 rounded-md "
                  dir="rtl"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="زیادکردنی ئاگاداری"
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {notifications.map((notification) => (
          <div
            key={notification.notificationId}
            className="notify p-2 gap-2 border-2 shadow-lg border-primary-100 rounded-md w-full flex   "
          >
            <div className=" center text-primary-100 font-kurdish_041  flex-[2] p-2">
              {notification.createdAt}
            </div>
            <div
              dir="rtl"
              className="  text-sm  flex-[7] center text-primary-100 font-kurdish_043 p-2"
            >
              {notification.message}
            </div>
            <div className=" aspect-square flex-[2]">
              <div className=" w-full h-full center text-white text-3xl aspect-square  rounded-full bg-primary-100">
                <IoIosNotificationsOutline />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
