import { useState } from "react";

export const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(null);

  const checkIsAdmin = async (userId) => {
    try {
      const response = await fetch("https://nwsinga-server.onrender.com/isadmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to check admin status.");
      }

      const data = await response.json();
      setIsAdmin(data.isAdmin);
      return data.isAdmin;
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };

  return [isAdmin, checkIsAdmin];
};
