import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { FaViber } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import Crown from "../../assets/images/crown.png";

const Posts = (props) => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, [props.filter]);

  useEffect(() => {
    filter();
  }, [posts]);

  const fetchPosts = async () => {
    try {
      const response = await fetch("https://nwsinga-server.onrender.com/posts");
      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }
      const data = await response.json();
      setPosts(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const filter = () => {
    if (props.filter === "none") {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter((post) => post.type === props.filter);
      setFilteredPosts(filtered);
    }
  };

  const compareVipPosts = (a, b) => {
    if (a.vip && !b.vip) {
      return -1;
    }
    if (!a.vip && b.vip) {
      return 1;
    }
    return 0;
  };

  return (
    <div className=" grid grid-cols-1 md:grid-cols-4 mt-2 w-full gap-4 p-4 pb-32">
      {filteredPosts.slice().reverse().sort(compareVipPosts).map((post, index) => (
        <div className=" min-w-full" key={index}>
          <div className="card min-w-full relative shadow-lg mb-6 pb-2">
            <Link to={`/post/${post.postId}`} className="image relative">
              {post.vip ? (
                <img
                  src={Crown}
                  className="absolute left-3 h-8 w-8 bg-[#e1b600] rounded-b-lg"
                  alt="h"
                />
              ) : (
                <></>
              )}
              <p className="text-sm flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute top-0 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg ">
                {post.address}
                <CiLocationOn className="text-md" />
              </p>
              <img
                src={post.images[0]} // Assuming the first image is the main image
                className="w-full aspect-square rounded-tr-lg rounded-tl-lg"
                alt={post.title}
              />
            </Link>
            <Link to={`/post/${post.postId}`} className="title center p-2">
              <p className="center text-center font-kurdish_043 text-xl md:text-lg "> {post.title} </p>
            </Link>
            <div className="flex p-2">
              <div className="flex-[1] flex flex-col text-center bg-primary-100 text-white rounded-md">
                <h6 className="font-semibold font-kurdish_042 px-4 py-2 center gap-2">
                  <span>{post.priceType}</span>
                  <span>{post.price}</span>{" "}
                </h6>
              </div>
              <div className="flex-[1] flex justify-center items-center text-primary-100 text-3xl gap-8">
                <Link to={`tel:${post.phoneNumber}`} className="phone">
                  <FiPhoneCall />
                </Link>
                <Link
                  to={`viber://chat?number=${post.phoneNumber}`}
                  className="phone"
                >
                  <FaViber className="text-[#675DA9]" />
                </Link>
                <Link
                  to={`https://api.whatsapp.com/send?phone=${post.phoneNumber}`}
                  className="phone"
                >
                  <FaWhatsapp className="text-[#25D366]" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Posts;
