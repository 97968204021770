import React, { useRef, useState } from "react";
import { MdOutlineAccountCircle, MdOutlineEmail } from "react-icons/md";
import { IoLockClosedOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState({});

  const register = async () => {
    try {
      setLoading(true);

      let error = {};

      if (!username) {
        error.username = true;
      }
      if (!email) {
        error.email = true;
      }
      if (!password) {
        error.password = true;
      }
      if (Object.keys(error).length > 0) {
        setErrors(error);
        return;
      }
      const lowerEmail = email.toLowerCase();
      const lowerUsername = username.toLowerCase();
      setEmail(lowerEmail);
      setUsername(lowerUsername);
      // console.log(email);

      const response = await fetch("https://nwsinga-server.onrender.com/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (!response.ok) {
        throw new Error("Failed to sign up.");
      }

      const data = await response.json();
      // Handle response data as needed

      // Redirect user after successful signup using React Router history
      window.location.href = "/login";
    } catch (error) {
      console.error("Error signing up:", error);
      // Handle error state here
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full bg-primary-300 flex flex-col items-center  ">
      <div className="container md:w-[32rem] h-full flex justify-center items-center flex-col p-8 ">
        <div className="image w-full flex-[1] center">
          <img
            src="https://i.ibb.co/xh8bWMt/rob1.png"
            alt="https://i.ibb.co/xh8bWMt/rob1.png"
            className="size-[10rem]"
          />
        </div>
        <div className="signUpForm w-full flex-[6] flex flex-col justify-start items-center p-2 gap-8 ">
          <div className="welcome text-3xl text-white">خۆتۆمارکردن</div>

          <div className="username w-full flex justify-center items-center mr-4">
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              type="text"
              className={`w-full h-full bg-transparent -mr-9 text-center placeholder-white p-4 rounded-full ${
                error.username ? "br" : "border-2 border-white "
              }`}
              placeholder="ناوی بەکارهێنەر"
            />
            <MdOutlineAccountCircle className="text-4xl -ml-4 text-white " />
          </div>
          <div className="email w-full flex justify-center items-center mr-4">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="text"
              className={`w-full h-full bg-transparent -mr-9 text-center placeholder-white p-4 rounded-full ${
                error.email ? "br" : "border-2 border-white "
              }`}
              placeholder="ئیمەیڵ "
            />
            <MdOutlineEmail className="text-4xl -ml-4 text-white " />
          </div>
          <div className="username w-full flex justify-center items-center mr-4 text-white placeholder-white">
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              className={`w-full h-full bg-transparent -mr-9 text-center placeholder-white p-4 rounded-full ${
                error.password ? "br" : "border-2 border-white  "
              }`}
              placeholder=" وشەی نهێنی"
            />
            <IoLockClosedOutline className="text-4xl -ml-4 text-white " />
          </div>
          <div className="username flex justify-center items-center">
            <button
              onClick={register}
              className="w-full h-full bg-white text-primary-100 text-center pt-2 pb-2 pl-4 pr-4 border-2 rounded-full text-3xl"
              // disabled={loading}
            >
              {loading ? "چاوەڕێ بکە!" : "تۆمارکردن"}
            </button>
          </div>
          {/* {error && (
            <div className="error-message text-red-500 text-center mt-2">
              {error}
            </div>
          )} */}
          <div className="alreadyHaveAnAcoount">
            <span className=" font-kurdish_041 text-white">ئەکاونتت هەیە؟</span>
            <Link
              to="/login"
              className="font-bold font-kurdish_043 text-1x1 text-white"
            >
              داخڵ ببە
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
