import React, { useRef, useState } from "react";
import { IoLockClosedOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import icon from "../../assets/images/icon.png"
export const Login = (props) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cookies = new Cookies();

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError(null);

      let email = emailRef.current.value;
      const password = passwordRef.current.value;

      // Convert email to lowercase
      email = email.toLowerCase();

      const response = await fetch("https://nwsinga-server.onrender.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Store session as cookie
        cookies.set("session", data.session, { path: "/" });
        cookies.set("isLoggedIn", true, { path: "/" });
        cookies.set("userId", data.data.userId, { path: "/" });

        // Optionally, you can store user data in cookies as well
        cookies.set("user", data.data, { path: "/" });

        // Redirect to home page or any other page upon successful login
        window.location.href = "/";
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred during login.");
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full bg-primary-300">
      <div className="design  w-full bg-white h-[100vh] flex flex-col items-center">
        <div className="TopLogin bg-primary-100 rounded-br-full shadow-lg flex-[2] w-full center p-8 ">
          <div className="NotHaveAccount flex flex-col justify-center items-center text-center gap-8 ">
            <span className="text-white font-kurdish_043 font-bold text-3xl">
              خۆت تۆمار نەکردووە ؟
            </span>
            <div className=" bg-white p-1 pr-2 pl-2 rounded-full ">
              <Link
                to="/signup"
                className="font-kurdish_043 text-1x1 text-primary-100  text-1xl "
              >
                خۆت تۆمار بکە
              </Link>
            </div>
          </div>
        </div>
        <div className="BootomLogin center  p-8 flex-[6] w-full md:w-[32rem] ">
          <div className="loginForm w-full flex-[2] flex flex-col justify-start items-center p-2 gap-8  ">
            <div className="NotHaveAccount flex flex-col justify-center items-center text-center gap-8 ">
              {/* <span className=" text-primary-100 font-kurdish_043 font-bold text-3xl">
                - بەخێربێیت -
              </span> */}
              <img src={icon} alt="" className=" w-[6rem] " />
            </div>
            <div className="username w-full flex justify-center items-center mr-4 ">
              <input
                ref={emailRef}
                type="text"
                className="w-full h-full bg-transparent placeholder-primary-100  -mr-9 text-center p-4 border-2 border-primary-100 rounded-full"
                placeholder="ئیمەیڵ "
              />
              <MdOutlineEmail className="text-4xl -ml-4 text-primary-100" />
            </div>
            <div className="password w-full flex justify-center items-center mr-4">
              <input
                ref={passwordRef}
                type="password"
                className="w-full h-full bg-transparent placeholder-primary-100 -mr-9 text-center p-4 border-2 border-primary-100 rounded-full"
                placeholder=" وشەی نهێنی"
              />
              <IoLockClosedOutline className="text-4xl -ml-4 text-primary-100" />
            </div>
            <div className="username flex justify-center items-center">
              <button
                onClick={handleLogin}
                className="w-full h-full text-white bg-primary-100 text-center pt-2 pb-2 pl-4 pr-4 border-2 rounded-full text-3xl"
                disabled={loading}
              >
                {loading ? "چاوەڕێ بکە !" : "چونەژورەوە"}
              </button>
            </div>
            {error && (
              <div className="error-message text-red-500 text-center mt-2">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
