import React, { useState } from "react";
const Banner = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  return (
    <>
      <div className="h-auto w-full bg-primary-200 relative z-20 p-4">
        <div className="flex justify-center items-center pt-2 ">
          <div className="flex items-center text-center p-2">
            <h2 className="font-bold text-white font-kurdish_043">سـەرەکــی</h2>
          </div>
        </div>

        {/* <div className="relative flex items-center justify-between p-[14px]">
          <LiaSearchSolid
            className="absolute right-16 top-[23px] font-thin text-white pointer-events-none stroke-current opacity-60"
            size={26}
          />
          <input
            type="text"
            className="h-10 w-full rounded-md border border-white bg-transparent pr-11 text-right text-white caret-white focus:border-white focus:outline-none"
            placeholder="گەڕان بکە"
          />
          <div className="flex">
            {isFilterOpen ? (
              <LuFilterX
                className="h-8 w-10 text-white"
                onClick={handleFilterClick}
              />
            ) : (
              <LuFilter
                className="h-8 w-10 text-white"
                onClick={handleFilterClick}
              />
            )}
          </div>
        </div> */}
      </div>
      {/* {isFilterOpen && <Filter />} */}
    </>
  );
};

export default Banner;
