import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
export const SideBarSettings = (props) => {
  return (
    <div className=" w-[50%] h-full fixed right-0 top-0 z-[50] gap-8 font-kurdish_042  bg-primary-100 border-b-2 border-l-2 rounded-b-lg flex flex-col justify-start items-center text-white pt-8 p-4">
      <IoMdCloseCircleOutline
        className="text-4xl"
        onClick={() => props.setOpen(false)}
      />
      <hr className="w-full " />
      <Link
        to={"/settings"}
        className="userProfileSettings border-2 px-4 py-2 hover:cursor-pointer rounded-md min-w-32 center"
      >
        ئەکاونتەکەم
      </Link>
      <Link
        to="/manage"
        className="userProfileSettings border-2 px-4 py-2 hover:cursor-pointer rounded-md  min-w-32 center"
      >
        موڵکەکانم
      </Link>
      <div className="userProfileSettings border-2 px-4 py-2 hover:cursor-pointer rounded-md  min-w-32 center">
        دەربارە
      </div>

      <div
        onClick={props.logOut}
        className="logoutbutton absolute bottom-32 border-2 text-primary-100 bg-white px-4 py-2 hover:cursor-pointer rounded-md  min-w-32 center"
      >
        چوونەدەرەوە
      </div>
    </div>
  );
};
