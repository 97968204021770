import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from "./Components/Navbar/Navbar";
import { SignUp } from "./Components/SignUp/SignUp";
import { Login } from "./Components/Login/Login";
import { Profile } from "./Components/Profile/Profile";
import Cookies from "universal-cookie";
import { UserForm } from "./Components/UserForm/UserForm";
import { Home } from "./Components/Home/Home";
import "../src/assets/fonts/font2.css";
import "./App.css";
import Post from "./Components/Post/Post";
import { Map } from "./Components/Map/Map";
import { Manage } from "./Components/Manage/Manage";
import { Upload } from "./Components/Upload/Upload";
import { UserProfile } from "./Components/UserProfile/UserProfile";
import { Edit } from "./Components/Edit/Edit";
import { Notification } from "./Components/Notification/Notification";
function App() {
  const cookies = new Cookies(null, { path: "/" });

  const [isLoggedIn, setLoggedIn] = useState(cookies.get("isLoggedIn"));
  const [user, setUser] = useState(cookies.get("userId"));
  // console.log(isLoggedIn);
  useEffect(() => {
    if (cookies.get("setLoggedIn")) {
      setLoggedIn(true);
      const userData = cookies.get("user");
      setUser(cookies.get("userId"));
    }
  }, []);
  const handleLogOut = () => {
    setLoggedIn(false);
    setUser(null);
    cookies.set("setLoggedIn", false);
    cookies.set("user", null);
  };
  return (
    <div className="h-screen ">
      <BrowserRouter>
        <Routes>
          <Route
            path="/signup"
            element={isLoggedIn ? <Navigate to="/" /> : <SignUp />}
          />
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/" />
              ) : (
                <Login setLoggedIn={setLoggedIn} setUser={setUser} />
              )
            }
          />
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <>
                  <Home />

                  <Navbar />

                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/Edit/:postId"
            element={
              isLoggedIn ? (
                <>
                  <Edit />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/notification"
            element={
              isLoggedIn ? (
                <>
                  <Notification />
                  <Navbar/>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/manage"
            element={
              isLoggedIn ? (
                <>
                  <Manage />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isLoggedIn ? (
                <>
                  <Profile handleLogOut={handleLogOut} />
                  {/* <div onClick={handleLogOut}>Logout</div> */}
                  <Navbar />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/post/:postId" element={<Post />} />
          <Route
            path="/upload"
            element={
              isLoggedIn ? (
                <>
                  <Upload />
                  {/* <Navbar /> */}
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            path="/map"
            element={
              isLoggedIn ? (
                <>
                  <Map />
                  <Navbar />
                  {/* Add your other components for the logged-in state */}
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/settings"
            element={<UserForm handleLogOut={handleLogOut} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
