import React, { useState } from "react";
import { LuFilter } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
export const Category = (props) => {
  const [filter1, setFilter1] = useState("none");
  const hanldeSetFilter = (value) => {
    props.setFilter(value);
    setFilter1(value);
  };
  const filters = ["خانوو", "شوقە", "دوکان", "ڤێلا", "زەوی"];
  return (
    <div className="w-full mt-2 mb-2 p-2 flex justify-around z-10 relative gap-4 flex-row-reverse">
      <div
        className="flex-1 flex rounded-lg gap-2 justify-center items-center text-center min-w-16 bg-white px-2 py-2 text-primary-100 border-2 border-primary-100 font-kurdish_042"
        onClick={() => hanldeSetFilter("none")}
      >
        {filter1 && filter1 == "none" ? (
          <>
            فلتەر
            <LuFilter />
          </>
        ) : (
          <IoMdClose className=" text-2xl" />
        )}
      </div>
      <div className="flex-[4] flex gap-2 overflow-x-scroll" dir="rtl">
        {filters.map((filter, index) => (
          <div
            key={index}
            className={`justify-center rounded-lg items-center text-center min-w-24 ${
              filter === filter1
                ? "bg-white border-2 border-primary-100 text-primary-100"
                : "bg-primary-200 text-white"
            } px-2 py-2  font-kurdish_042`}
            onClick={() => hanldeSetFilter(filter)}
          >
            {filter}
          </div>
        ))}
      </div>
    </div>
  );
};
