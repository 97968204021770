import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export const Map = () => {
  const center = {
    lat: 36.18821396643311,
    lng: 44.010066159349506,
  };

  const [posts, setPosts] = useState([]);
  const [fill, setFill] = useState([]);

  useEffect(() => {
    fetch("https://nwsinga-server.onrender.com/posts")
      .then((response) => response.json())
      .then((data) => setPosts(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleHeart = (index) => {
    const updatedFill = [...fill];
    updatedFill[index] = !updatedFill[index];
    setFill(updatedFill);
  };

  return (
    <div className="w-full h-full flex mx-auto top-0 left-0 fixed bg-gray-200 z-50">
      <MapContainer
        center={center}
        zoom={7}
        scrollWheelZoom={true}
        className="w-full h-full"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {posts.map((post, index) => (
          <Link
            to={`/post/${post.postId}`}
            className="carousel-item"
            key={index}
          >
            <Marker position={[post.position.lat, post.position.lng]}>
              <Popup minWidth={100}>
                <div className="card relative shadow-lg mb-6">
                  <div className="image relative">
                    <p className="text-xs flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute -top-4 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg">
                      {post.address}
                      <CiLocationOn className="text-md" />
                    </p>
                    <img
                      src={post.images[0]}
                      className="w-full aspect-square rounded-tr-lg rounded-tl-lg"
                      alt={post.title}
                    />
                  </div>
                  <div className="title center p-2">
                    <p className="center text-center font-kurdish_043 text-md">
                      {post.title}
                    </p>
                  </div>
                  <div className="flex p-2">
                    <div className="flex-[1] flex flex-col text-center bg-primary-100 text-white rounded-md">
                      <h6 className="font-semibold font-kurdish_042 px-4 py-2 center gap-2">
                        <span>{post.priceType}</span>
                        <span>{post.price}</span>
                      </h6>
                    </div>
                    
                  </div>
                </div>
              </Popup>
            </Marker>
          </Link>
        ))}
      </MapContainer>
    </div>
  );
};
